import React, { useState, useEffect } from 'react'
import {  BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import axios from 'axios';
import PrivateRoute from './component/auth/PrivateRoute';
import Dashboard from './component/adminPages/Dashboard'; 
import NavHead from './component/Nav/NavHead'; 
import NavFoot from './component/Nav/NavFoot';
import AuthAdmin from './component/auth/AuthAdmin';
import Allparent from './component/adminPages/parent/Allparent';
import Alltutor from './component/adminPages/tutor/Alltutor';

import Subject from './component/adminPages/Subject';
import ErrorPage from './component/auth/ErrorPage';
import InvigilatorDownload  from './component/academic/InvigilatorDownload'
import  CaptureAssessment  from './component/academic/CaptureAssessment';
import  UploadAssessment  from './component/academic/UploadAssessment';
import  AssessmentSummary  from './component/academic/AssessmentSummary';
import TermFinal from './component/academic/TermsFinal';
import Uploadquestion from './component/academic/Uploadquestion';
import Support from './component/adminPages/Support';
import Staff from './component/adminPages/Staff';
import Fee from './component/adminPages/finance/Fee';
import GenerateInvoice from './component/adminPages/finance/GenerateInvoice';
import PaidInvoice from './component/adminPages/finance/PaidInvoice';
import UnpaidInvoice from './component/adminPages/finance/UnpaidInvoice';
import CentreDetails from './component/adminPages/tutor/CentreDetails';
import StudentInfo from './component/adminPages/parent/StudentInfo';
import ParentInfo from './component/adminPages/parent/ParentInfo';
import TutorInfo from './component/adminPages/tutor/TutorInfo';
import Request from './component/adminPages/Request';
import RequestDetails from './component/adminPages/RequestDetails';
import ResetPass from './component/adminPages/ResetPass';
import OutResetPass from './component/adminPages/OutResetPass';
import Allstudent from './component/adminPages/parent/Allstudent';

const serverURL = "https://equationserver.com";
const innerServer = "http://localhost:3001";
axios.defaults.baseURL = serverURL;

function App() {
  axios.defaults.withCredentials = true;
  const site_name = 'FastExcellence';
    const [authenticate, setAuthenticate] = useState(false);
    

     const checkAdminLog = (aut) =>{
          setAuthenticate(aut);
     }

   useEffect(()=>{
         axios.get('/monitor-adminlog',{
          headers: { 'Content-Type': 'application/json'}
         })
         .then((response)=>{
              if(response.data.adminLoggin=== 'allow'){
                      setAuthenticate(true);
              }else
              {
                    setAuthenticate(false);
              }
         }).catch(err=>{
           console.log(err)
         })

   },[])

      if(authenticate !== false){
        return (
          <div className="App">
                <Router>
                  <NavHead siteName={site_name} authenCheck={checkAdminLog}/>
              <Routes>
              <Route element={<PrivateRoute isAuth={authenticate} />}>

                <Route path='/' element={<Dashboard />}  />
                <Route path='/dashboard' element={<Dashboard />}  />
                <Route path="/parents" element={<Allparent />}  />
                <Route path="/tutors" element={<Alltutor />} />
                <Route path="/settings" element={<ResetPass />}  />
                <Route path="/questionupl" element={<Uploadquestion />}  />
                <Route path="/generate-invoice" element={<GenerateInvoice />}  />
                <Route path="/subject" element={<Subject />}  />
                <Route path="/admintutorupload" element={<InvigilatorDownload />} />
                <Route path="/capture-assessment" element={<CaptureAssessment />}  />
                <Route path="/upload-assessment" element={<UploadAssessment />}  />
                <Route path="/term-final" element={ <TermFinal />} />
                <Route path="/support" element={ <Support /> }  />
                <Route path="/staff" element={ <Staff /> } />
                <Route path="/assessment-summary" element={ <AssessmentSummary /> } />
                <Route path="/fee-setup" element={ <Fee /> } />
                <Route path="/paid-invoice" element={ <PaidInvoice /> } />
                <Route path="/unpaid-invoice" element={ <UnpaidInvoice /> } />
                <Route path="/centre/detials/:id" element={ <CentreDetails /> }  />
                <Route path="/student-data/details/:id/:user" element={<StudentInfo />} />
                <Route path="/parentinfo-data/:id" element={<ParentInfo />} />
                <Route path="/tutors/:id" element={<TutorInfo />} />
                <Route path="/request" element={<Request />} />
                <Route path="/request/:id" element={<RequestDetails />} />
                <Route path="/all-student" element={<Allstudent />}  />
                <Route path="*" element={<ErrorPage auth={authenticate} />} />
                
                </Route>
              </Routes>
                <NavFoot siteName={site_name} />
            </Router>
            
          </div>
        );

      }else
      {
        return (
          <div className="App">
            <Router>
              <Routes>
                 <Route path="/" element={<AuthAdmin checkAuth={checkAdminLog} />} />
                  
              

                 <Route path="/login" element={ <AuthAdmin checkAuth={checkAdminLog} />} />
                  
               

                 <Route path="/forgot-pass" element={  <OutResetPass />} />
                 
                 <Route path="*" element={ <ErrorPage auth={authenticate} />} />

             
              </Routes>

            </Router>
            
          </div>
        );
      }
  
}

export default App;
